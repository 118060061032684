import React from 'react';
import { graphql } from 'gatsby';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import DefaultLayout from '../../layout/DefaultLayout';
import ContentBlock from '@mangoart/gatsby-ui/components/ezagrar/ContentBlock';

const UnternehmenPage = ({ data, location }) => {
  const { pageData, siteData } = data;
  const { siteMetadata } = siteData;
  const { metadata, opengraph, content } = pageData;
  return (
    <DefaultLayout>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
      <ContentBlock data={content} cmsUrl="https://meintraktor.cms.mangomedia.at/" />
    </DefaultLayout>
  );
};

export default UnternehmenPage;

export const UnternehmenPageQuery = graphql`
  query UnternehmenPageQuery {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    pageData: cockpitPageUnternehmen {
      content {
        title
        icon
        teaser
        cssclass
        text
        image {
          childImageSharp {
            gatsbyImageData(width: 1600)
          }
        }
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          childImageSharp {
            gatsbyImageData(width: 1300, height: 630)
          }
        }
      }
    }
  }
`;
